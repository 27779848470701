<template>
  <div class="iotProduct">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot
        ><v-button text="导入" @click="toImport"></v-button>
        <v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <community-select
          spaceTypes="10,30"
          specifyCollectionTypes=""
          label="选择园区"
          v-model="searchParam.spaceId"
        />
        <v-input label="产品名称" v-model="searchParam.name" />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          type="text"
          @click="toEdit(scope.row)"
        />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import {
  getIotProductList,
  partyMemberDetailUrl,
  editPartyMemberUrl,
  deleteProUrl,
} from "./api.js";
import { isPartyStatusMap, isCadreMap } from "./map.js";
import {
  createAlinkVNode,
  createImgVNode,
  createHTMLVNode,
} from "@/utils/utils.js";

export default {
  name: "realNameAuditList",
  data() {
    return {
      searchParam: {
        name: "",
        spaceId: "",
      },

      tableUrl: getIotProductList,
      headers: [
        {
          prop: "name",
          label: "名称",
        },
        {
          prop: "nodeTypeName",
          label: "产品类型",
        },
        {
          prop: "categoryName",
          label: "分类",
        },
        {
          prop: "netTypeName",
          label: "入网类型",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
        // {
        //   prop: "avatarUrl",
        //   label: "图片",
        //   formatter: (row, prop) => createImgVNode(this, row, prop),
        // },
        // {
        //   prop: "isPartyMember",
        //   label: "是否党员",
        //   formatter: (row, prop) => {
        //     return isPartyStatusMap[row.isPartyMember];
        //   },
        // },
      ],
    };
  },
  computed: {},
  methods: {
    toImport() {
      this.$router.push({
        name: "iotProductImportForm",
      });
    },
    toDelete(item) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          deviceId: item.id,
        };
        this.$axios.post(`${deleteProUrl(params)}`).then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.$refs.list.search();
          } else {
          }
        });
      });
    },

    toAdd() {
      this.$router.push({
        name: "iotProductForm",
      });
    },
    toEdit(row) {
      this.$router.push({
        name: "iotProductForm",
        query: {
          id: row.id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.iotProduct {
  box-sizing: border-box;
  height: 100%;
}
</style>
