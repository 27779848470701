import { mapHelper } from "@/utils/common.js";
const valueTypeList = [
  {
    value: "FLOAT",
    label: "FLOAT",
  },
  {
    value: "INT",
    label: "INT",
  },
  {
    value: "STRING",
    label: "STRING",
  },
];
const { map: valueTypeListMap, setOps: valueTypeListOps } =
  mapHelper.setMap(valueTypeList);
const isFill = [
  {
    value: true,
    label: "是",
  },
  {
    value: false,
    label: "否",
  },
];
const { map: isFillMap, setOps: isFillOps } = mapHelper.setMap(isFill);
//属性权限
const valueAuth = [
  {
    value: "READONLY",
    label: "只读",
  },
  {
    value: "WRITE",
    label: "读写",
  },
];
const { map: valueAuthMap, setOps: valueAuthOps } = mapHelper.setMap(valueAuth);
//告警类型0 提示 1告警 2故障
const warnType = [
  {
    value: "0",
    label: "提示",
  },
  {
    value: "1",
    label: "告警",
  },
  {
    value: "2",
    label: "故障",
  },
];
const { map: warnTypeMap, setOps: warnTypeOps } = mapHelper.setMap(warnType);
//通知方式
const noticeWay = [
  {
    value: "SMS",
    label: "短信",
  },
];
const { map: noticeWayMap, setOps: noticeWayOps } = mapHelper.setMap(noticeWay);
export {
  valueTypeListMap,
  valueTypeList,
  isFillMap,
  isFill,
  valueAuth,
  valueAuthMap,
  warnTypeMap,
  warnType,
  noticeWay,
  noticeWayMap,
};
